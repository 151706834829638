<template>
    <div class="jh-container">
        <div class="jh-ui-header">
          <div class="is-left">
            <h1>채팅 상담사 현황</h1>
            <label style="font-weight:bold;width:70px;text-align:right;">문의유형</label>
            <v-select class="jh-form is-round is-ml-10" style="width: 120px;" v-model="select_inqry_code_1" :items="inqry_code_1" item-value="CD" item-text="CD_NM"></v-select>
            <v-btn class="jh-btn is-round is-search" @click="selectRtnAgentDashboard">조회</v-btn>
          </div>
          <div class="is-right">
              <label class="jh-label">최근 업데이트 :</label>
              <span class="is-value is-txt-blue">{{ recentUpdateTime }}</span>
              <!--<v-btn class="jh-btn is-icon" @click="selectRtnAgentDashboard" v-if="this.mixin_set_btn(this.$options.name, 'btnRefresh')"><i class="jh-icon-refresh"></i></v-btn>-->
          </div>
        </div>
        <div class="jh-form-wrap">
            <ul class="jh-tabs-dashboard">
                <li :class="{ 'is-active': status.ALL }" @click="toggle('ALL')">
                    <span class="is-label"><i class="jh-icon-dashboard is-all"></i>전체</span>
                    <span class="is-value">{{ statusType[0].AGENT_ALL_CNT }}</span>
                </li>
                <li :class="{ 'is-active': status.CHAT_ON }" @click="toggle('CHAT_ON')">
                    <span class="is-label"><i class="jh-icon-dashboard is-login"></i>채팅ON</span>
                    <span class="is-value">{{ statusType[0].AGENT_CHAT_ON }}</span>
                </li>
                <li :class="{ 'is-active': status.READY }" @click="toggle('READY')">
                    <span class="is-label"><i class="jh-icon-dashboard is-ready"></i>상담대기 직원</span>
                    <span class="is-value">{{ statusType[0].AGENT_READY_CNT }}</span>
                </li>
                <li :class="{ 'is-active': status.IN_CONSULTATION }" @click="toggle('IN_CONSULTATION')">
                    <span class="is-label"><i class="jh-icon-dashboard is-counsel"></i>상담중 직원</span>
                    <span class="is-value">{{ statusType[0].AGENT_IN_CONSULTATION_CNT }}</span>
                </li>
                <li :class="{ 'is-active': status.AWAY }" @click="toggle('AWAY')">
                    <span class="is-label"><i class="jh-icon-dashboard is-away"></i>이석</span>
                    <span class="is-value">{{ statusType[0].AGENT_AWAY_CNT }}</span>
                </li>
                <!--
                <li :class="{ 'is-active': status.LOGOUT }" @click="toggle('LOGOUT')">
                    <span class="is-label"><i class="jh-icon-dashboard is-logout"></i>로그아웃</span>
                    <span class="is-value">{{ statusType[0].LOGOUT }}</span>
                </li>
                -->
            </ul>
            <v-data-table
            class="jh-grid is-mt-10"
            height="620px"
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="200"
            item-key="index"
            fixed-header
            hide-default-header
            hide-default-footer
            @click:row="showRowInfo"
            >
              <template v-slot:header>
                <thead class="v-data-table-header">
                  <tr>
                    <th width="60px"><span>NO</span></th>
                    <th width="120px"><span>상담직원/사번</span></th>
                    <th width="120px"><span>권한그룹</span></th>
                    <th width="100px"><span>상태</span></th>
                    <th width="100px"><span>배정제한(건)</span></th>
                    <th width="100px"><span>배정가능(건)</span></th>

                    <th width="100px"><span>상담진행(건)</span></th>
                    <th width="100px"><span>대기</span></th>
                    <th width="100px"><span>상담중</span></th>

                    <th width="140px"><span>후처리(건)</span></th>
                    <th width="140px"><span>상담완료(누적/건)</span></th>
                    <th width="140px"><span>채팅시간</span></th>
                    <th width="140px"><span>후처리시간</span></th>
                    <th width="140px"><span>이석시간</span></th>
                  </tr>
                  <tr>
                    
                  </tr>
                  
                </thead>
              </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";
let jsonObj;

export default {
  name: "MENU_E020201", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  data: () => ({
    selected: '001',
    select_inqry_code_1:'',
    inqry_code_1: [],
    status: {
      ALL: true,
      CHAT_ON: false,
      READY: false,
      IN_CONSULTATION: false,
      AWAY: false,
      LOGOUT: false,
    },
    recentUpdateTime: "",
    statusType: [
      {
        AGENT_ALL_CNT: 0,
        AGENT_CHAT_ON_CNT: 0,
        AGENT_READY_CNT: 0,
        AGENT_IN_CONSULTATION_CNT: 0,
        AGENT_AWAY_CNT: 0,
        AGENT_LOGOUT_CNT: 0,
      },
    ],
    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    gridDataHeaders: [
      { text: "NO", value: "index", align: "center", width: '60px'},
      { text: "상담직원/사번", value: "USER_ID", align: "center", width: '150px'},
      // { text: "사용자명", value: "USER_NM", align: "center", },
    //   { text: "사용자닉네임", value: "USER_NICK", align: "center", },
      { text: "권한그룹", value: "ATRT_GROUP_NM", align: "center", },
      { text: "상태", value: "LAST_USER_STATUS_CD", align: "center", width: '100px'},
      // { text: "상태시작시간", value: "STATUS_START_TIME", align: "center", },
      { text: "배정제한(건)", value: "NUM_OF_CONSUL_ALLOWED", align: "center", width: '120px'},
      { text: "배정가능(건)", value: "NUM_OF_CONSUL_AVAILABLE", align: "center", width: '120px'},

      { text: "상담진행(건)", value: "NUM_OF_CONSUL", align: "center", width: '120px'},
      { text: "대기", value: "READY_COUNSEL_CNT", align: "center", },
      { text: "상담중", value: "ING_COUNSEL_CNT", align: "center", width: '120px'},

      { text: "후처리(건)", value: "AFTER_COUNSEL_CNT", align: "center", width: '120px'},
      { text: "상담완료(누적/건)", value: "CUMULATIVE_CNSL_CMPL", align: "center", width: '120px'},
      { text: "채팅시간", value: "CUMULATIVE_CHAT_TIME", align: "center", width: '120px'},
      { text: "후처리시간", value: "CUMULATIVE_AFTER_TIME", align: "center", width: '120px'},
      { text: "이석시간", value: "CUMULATIVE_AWAY_TIME", align: "center", width: '120px'},
    ],
    gridDataText: [],
    gridRowDetail: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    totalVisible: 10,
  }),
  methods: {
    selectValidation(){
      if (this.selected == "" || this.selected == undefined) {
        this.common_alert("회사를 먼저 선택해주세요.","error");
        return false;
      }
      return true;
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    showRowInfo(event, { item }) {
      this.editedIndex = this.gridDataText.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.gridRowDetail = this.editedItem;
    },
    //대시보드 탭의 카운트 수를 조회한다.
    async selectRtnAgentDashboard() {
    //   if(!this.selectValidation()) return;

      //현재 활성화된 탭
      let currentSelected = '';
      for(let key in this.status) {
        if(this.status[key]) currentSelected = key;
      }
      let URLData = "/api/chat/status/agent/dashboard/inqire";
      let HEADER_SERVICE = "chat.status.agent.dashboard";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 50;
      let HEADER_PAGES_CNT = 1;

      let jsonData = {
        ASP_NEWCUST_KEY: this.selected,
        ASP_CUST_KEY: this.selected,
        USER_ATTR_A : this.getUSER_ATTR_A(this.select_inqry_code_1),
      };

      //api 호출 부분
      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
            ASYNC: "false",
          },
        })
        .then((response) => {
          //status count 세팅
          jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          this.statusType = jsonObj;

          //최근 업데이트 날짜 갱신
          this.recentUpdateTime = new Date().toLocaleString();

          //해당 탭 활성화 (여기서 selectRtnAgentMonitoringStatus() 호출 )
          this.toggle(currentSelected);
          
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
          console.log(err);
        });
    },
    toggle(value) { //대시보드 탭 클릭시 그리드 show/hide
      //validation
      if(!this.selectValidation()) return;

      //모든 값을 false로 바꾸고
      for (let key in this.status) {
        this.status[key] = false;
      }
      //value에 해당하는 값만 true로 바꾼다.
      this.status[value] = true;
      //value에 해당하는 조회 요청을 서버로 보낸다.
      this.selectRtnAgentMonitoringStatus(value);
    },
    //각 탭의 상세 내용을 조회해 그리드에 로드한다.
    async selectRtnAgentMonitoringStatus(dashboardType) {
      let URLData = "api/chat/status/agent/sttus/inqire";
      let HEADER_SERVICE = "chat.status.agent.sttus";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 50;
      let HEADER_PAGES_CNT = 1;

      let jsonData = {
        ASP_CUST_KEY: this.selected,
        ASP_NEWCUST_KEY: this.selected,
        DASHBOARD_TYPE: dashboardType == undefined || dashboardType == "" ? "ALL" : dashboardType,
        USER_ATTR_A : this.getUSER_ATTR_A(this.select_inqry_code_1),
      };
      //api 호출 부분
      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
            ASYNC: "false",
          },
        })
        .then((response) => {
          const jsonReturn = JSON.parse(JSON.stringify(response.data.DATA));
          for (let i in jsonReturn) {
            jsonReturn[i].index = Number(i) + 1;
          }
          this.gridDataText = jsonReturn;
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
          console.log(err);
        });
    },

    getUSER_ATTR_A(inqry_code){
      if(inqry_code == '20221111160117978INQRYHa7Rc'){
        return '027'
      } else if (inqry_code == '20221111160118019INQRY2ZyB8'){
        return '100'
      } else if (inqry_code == '20221111160118034INQRYpu5Us'){
        return '038'
      } else {
        return ''
      }
    },

  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  async mounted() {
    this.inqry_code_1 = await this.mixin_inqry_code_get("001", "*");
    this.inqry_code_1.unshift({ CD_NM: "전체", CD: "" });
    this.selectRtnAgentDashboard();
  },
};
</script>

<style></style>
